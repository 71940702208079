<template>
    <div class="error-container">
        <span class="error-text">{{ text }}</span>
    </div>
</template>

<script>
export default {
    props: {
        text: String
    }
}
</script>

<style>
.error-container {
    width: 100%;
    border-radius: 8px;
    border: 1px solid #FF254C;
    padding: 12px 16px;
    margin-bottom: 24px;
    
    background-color: #ff254d10;
}
.error-text {
    font-family: 'Wix Madefor Display', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;

    color: #FF254C;
}
</style>
